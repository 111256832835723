import React from "react";
import { GrCertificate } from "react-icons/gr";
import { IoMdCheckmark, IoMdTime } from "react-icons/io";
import { LiaUserGraduateSolid } from "react-icons/lia";
import { LuShieldCheck } from "react-icons/lu";
import { SiSololearn, SiTicktick } from "react-icons/si";
import { VscGitPullRequestCreate } from "react-icons/vsc";
import AboutCard from "./AboutusCard";

export default function Offer({ sectionRef }) {
  return (
    <>
      <div ref={sectionRef} id="about">
        <div className="p-3">
          <h2 className="container-heading">About Us</h2>
          <div className="row">
            <div className="col-md-12">
              <div className="offer">
                {/* <h3>What we Offer</h3> */}

                <p className="mb-4" data-aos="fade-up">
                  Yazid Memorial Academy (YMA), established in September 2016,
                  began with 120 pupils/students and now employs 40 academic and
                  non-academic staff. YMA offers Pre-Nursery, Nursery, Primary,
                  and Secondary education, focusing on a student-centered
                  environment aimed at fostering self-reliance and success. The
                  academy stands out for its affordable fees, flexible subject
                  offerings aligned with WAEC and NECO requirements, and small
                  class sizes, ensuring personalized learning with qualified
                  staff. The management appreciates your continued support and
                  looks forward to future patronage.
                </p>

                <div className="row mt-5 mb-5">
                  <AboutCard
                    heading="Our Mission"
                    describtion="OUR mission is to prepare each student's self esteem by posturing physical,mental and emotional development that are necessary for the world of work or further academic pursuit upon graduation."
                    time={100}
                  />
                  <AboutCard
                    heading="Our Vision"
                    describtion="Our vission at yazid memorial academy is to produce and empower our student to acquire basic skills and knowledge that could make them realise their potentials in becoming self reliant and useful citizens in the society."
                    time={200}
                  />
                  <AboutCard
                    heading="Core Values "
                    describtion="Our core values guide our institution, emphasizing integrity, excellence, inclusivity, and innovation. They inspire a supportive learning environment where every individual can thrive and positively contribute to society."
                    time={300}
                  />
                </div>
                {/* <div className="row ps-md-5">
                  <div
                    className="col-md-6 col-sm-12 offer-icon-section"
                    data-aos="fade-right"
                  >
                    <div className="offer-icon">
                      <LuShieldCheck />
                    </div>
                    <div className="offer-icon-desc">
                      <h2>Safety First</h2>
                      <p>
                        Nestled in a secure haven, where learning thrives amidst
                        care and vigilance.
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-6 col-sm-12 offer-icon-section"
                    data-aos="fade-left"
                  >
                    <div className="offer-icon">
                      <IoMdTime />
                    </div>
                    <div className="offer-icon-desc">
                      <h2>Regular Classes</h2>
                      <p>
                        Structured learning sessions held consistently to
                        enhance knowledge and skills.
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-6 col-sm-12 offer-icon-section"
                    data-aos="fade-right"
                  >
                    <div className="offer-icon">
                      <GrCertificate />
                    </div>
                    <div className="offer-icon-desc">
                      <h2>Experienced Educators</h2>
                      <p>
                        Skilled professionals committed to delivering quality
                        education and nurturing student growth.
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-6 col-sm-12 offer-icon-section"
                    data-aos="fade-left"
                  >
                    <div className="offer-icon">
                      <VscGitPullRequestCreate />
                    </div>
                    <div className="offer-icon-desc">
                      <h2>Creative Lessons</h2>
                      <p>
                        Innovative and engaging lessons designed to inspire
                        imagination and foster creativity.
                      </p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div className="col-lg-5 offer-side">
            <h2>welcome</h2>
            <p>jsjsjshjsjauwhjehsu</p>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
